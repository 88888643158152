import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

window.searchCookieValue = function(key)
{
    const cookies = document.cookie;
    const cookiesArray = cookies.replace(/\s+/g, '').split(';');
    for(var cookie of cookiesArray) {
        const cArray = cookie.split('=');
        if( cArray[0] == key) {
            return decodeURIComponent(cArray[1]);
        }
    }
};

window.setCookie = function(key, value)
{
    document.cookie = key + "=" + encodeURIComponent(value);
};

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

import 'foundation-datepicker';

$(document).foundation();

